import { useEffect, useMemo, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { NameType } from "recharts/types/component/DefaultTooltipContent";
import { formatDuration, generateTicks } from "./helper-functions";

const stringToColor = (str: string) => {
  const colors = [
    "#208eb7",
    "#62d7e1",
    "#1c4c5e",
    "#a3c9fe",
    "#30408d",
    "#7771ff",
    "#edb1ff",
    "#621da6",
    "#eb67f9",
    "#ab6eaf",
  ];
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return colors[Math.abs(hash) % colors.length];
};

// Define custom props type for the custom bar shape
interface CustomBarShapeProps {
  x: number;
  y: number;
  width: number;
  height: number;
  fill: string;
  // Include other properties if needed
}

// Updated customBarShape function
const customBarShape = (props: unknown): JSX.Element => {
  const { fill, x, y, width, height } = props as CustomBarShapeProps;
  return (
    <rect fill={fill} x={x} y={y} width={width} height={height} rx={5} ry={5} />
  );
};

// Tooltip rendering function
const renderTooltip = ({ active, payload }: TooltipProps<number, NameType>) => {
  if (!active || !payload || !payload.length) return null;

  const thumb = payload[0]?.payload.date + " of October, 2024";
  const totalDuration = formatDuration(
    payload.reduce((p1, p2) => p1 + (p2?.value ?? 0), 0) as number
  );

  payload.sort((a, b) => (b.value as number) - (a.value as number));

  return (
    <div>
      <p className="rounded-t-sm bg-[#FAFAFA] px-3 py-2 text-sm font-medium">
        {thumb}
      </p>
      {payload.map((p) => {
        if (p.dataKey === "gap") return null;

        const key = p.dataKey as string;
        const time = formatDuration((p.value as number) ?? 0);
        const color = p.color as string;

        return (
          <div
            className="flex justify-between space-x-2 bg-white px-3 py-2 last:rounded-b-sm"
            key={key}
          >
            <div className="flex items-center gap-2">
              <div
                className="h-[5px] w-6 rounded-sm"
                style={{ backgroundColor: color }}
              ></div>
              <p className="text-sm font-medium">{p.dataKey}</p>
            </div>
            <span className="font-medium text-sm">{time}</span>
          </div>
        );
      })}
      <div className="flex justify-between space-x-2 bg-white px-3 py-2 last:rounded-b-sm">
        <div className="flex items-center gap-2">
          <p className="font-bold text-sm">Total</p>
        </div>
        <span className="font-bold text-sm">{totalDuration}</span>
      </div>
    </div>
  );
};

// Define the type for data items
type DataItem = {
  date: string;
  [key: string]: number | string | undefined;
};

export default function TimeTrackingGraph() {
  const data: DataItem[] = useMemo(
    () => [
      {
        date: "5th",
        "AI-mango-image-classifier": 317 * 60000,
        "e-commerce-idea-7": 192 * 60000,
        "petstore-assign-final": 153 * 60000,
        "custom-game-engine": 223 * 60000,
      },
      {
        date: "6th",
        "AI-mango-image-classifier": 249 * 60000,
        "lisa-discord-bot": 297 * 60000,
      },
      {
        date: "7th",
        "e-commerce-idea-7": 274 * 60000,
        "petstore-assign-final": 182 * 60000,
        "chronoly-landing-page": 223 * 60000,
        "chronoly-website-app": 308 * 60000,
        "untitled-file-Copy.txt": 45 * 60000,
        "gogoaeatery-website": 25 * 60000,
      },
      { date: "8th", "chronoly-argocd": 233 * 60000 },
      {
        date: "9th",
        "chronoly-landing-page": 239 * 60000,
        "e-commerce-idea-7": 258 * 60000,
        "AI-mango-image-classifier": 213 * 60000,
        "petstore-assign-final": 149 * 60000,
        "joes-pizza-app": 75 * 60000,
      },
      {
        date: "10th",
        "chronoly-website-app": 277 * 60000,
        "hotel-booking-system": 172 * 60000,
        "chronoly-argocd": 193 * 60000,
      },
      {
        date: "11th",
        "lisa-discord-bot": 302 * 60000,
        "AI-mango-image-classifier": 241 * 60000,
        "chronoly-landing-page": 212 * 60000,
        "petstore-assign-final": 219 * 60000,
        "songexile/Y2K-website-aesthetic": 15 * 60000,
      },
      {
        date: "12th",
        "TacticalAxis/auckland-transport": 290 * 60000,
        "e-commerce-idea-7": 230 * 60000,
        "ERC721-test": 110 * 60000,
      },
      {
        date: "13th",
        "wobkobi/reactionBot": 310 * 60000,
        "facebook/react": 199 * 60000,
        "AI-mango-image-classifier": 230 * 60000,
      },
      { date: "14th", "chronoly-website-app": 280 * 60000 },
      {
        date: "15th",
        "ERC721-test": 276 * 60000,
        "hotel-booking-system": 182 * 60000,
        "facebook/react": 203 * 60000,
      },
      {
        date: "16th",
        "wobkobi/reactionBot": 308 * 60000,
        "e-commerce-idea-7": 241 * 60000,
        "custom-game-engine": 180 * 60000,
        recharts: 22 * 60000,
      },
      {
        date: "17th",
        "chronoly-website-app": 268 * 60000,
        "lisa-discord-bot": 294 * 60000,
        "ERC721-test": 210 * 60000,
        "facebook/react": 225 * 60000,
        "trpc/react-query": 18 * 60000,
        zod: 35 * 60000,
      },
      {
        date: "18th",
        "hotel-booking-system": 185 * 60000,
        "wobkobi/reactionBot": 298 * 60000,
        "chronoly-landing-page": 218 * 60000,
        "TacticalAxis/auckland-transport": 230 * 60000,
        "tailwind-merge": 50 * 60000,
      },
      {
        date: "19th",
        "chronoly-argocd": 310 * 60000,
        "gogoaeatery-website": 90 * 60000,
      },
    ],
    []
  );

  const projectNames = Array.from(
    new Set(
      data.flatMap((item) => Object.keys(item).filter((key) => key !== "date"))
    )
  );

  const maxTotalDuration = 54000;

  // State variables for window width and display data
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const [displayData, setDisplayData] = useState<DataItem[]>(data);

  // Update window width on resize
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWindowWidth(window.innerWidth);

      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  // Update display data based on window width
  useEffect(() => {
    if (windowWidth < 1024) {
      // Show only the last 7 data items
      setDisplayData(data.slice(-7));
    } else {
      // Show all data items
      setDisplayData(data);
    }
  }, [windowWidth, data]);

  return (
    <ResponsiveContainer width="100%" height={450}>
      <BarChart data={displayData} barSize={50}>
        <Tooltip content={renderTooltip} />
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          xAxisId={0}
          dy={0}
          dx={-0}
          label={{ value: "", angle: 0, position: "bottom" }}
          interval={windowWidth < 1024 ? 0 : "preserveStartEnd"}
          dataKey="date"
          tickLine={false}
          axisLine={false}
          tick={{ fontSize: 12 }}
        />
        <XAxis
          xAxisId={1}
          orientation="bottom"
          dy={0}
          label={{
            value: "October",
            position: "bottom",
            fontSize: 14,
            dy: -30,
          }}
          tick={false}
          axisLine={false}
        />
        <YAxis
          axisLine={false}
          tickLine={true}
          yAxisId={0}
          domain={[0, maxTotalDuration]}
          ticks={generateTicks(maxTotalDuration)}
          tickFormatter={(value) => formatDuration(value)}
          tick={{ fontSize: 12 }}
        />
        {projectNames.map((project) => (
          <Bar
            isAnimationActive={false}
            key={project}
            dataKey={project}
            fill={stringToColor(project)}
            stackId="a"
            shape={customBarShape}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}
