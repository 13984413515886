export default function FeatureBlock({
  title,
  descriptionTitle,
  description,
  children,
}: {
  title: string;
  descriptionTitle: string;
  description: string;
  children: React.ReactNode;
}) {
  return (
    <div className="flex flex-col justify-center items-center py-8 text-center">
      <p className="text-4xl">{title}</p>
      <div>
        <p className="text-2xl font-bold">{descriptionTitle}</p>
        <p className="text-xl max-w-4xl mx-auto">{description}</p>
      </div>
      <div className="mt-8">{children}</div>
    </div>
  );
}
