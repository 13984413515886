"use client";

import Analytics from "~/app/_components/landing/features/analytics";
import CallToAction from "~/app/_components/landing/features/call-to-action";
import Invoice from "~/app/_components/landing/features/invoice";
import TimeTracking from "~/app/_components/landing/features/time-tracking";
import Hero from "~/app/_components/landing/hero";

export default function LandingPage() {
  return (
    <div className="my-24">
      <Hero />
      <TimeTracking />
      <Analytics />
      <Invoice />
      <CallToAction />
      {/* <Problem />
      <Solutions />
      <Features />
      <FAQ /> */}
    </div>
  );
}
