import AnalyticsCarousel from "../landing-interactables/analytics-carousel";
import FeatureBlock from "./feature";

export default function Analytics() {
  return (
    <FeatureBlock
      title="Analytics"
      descriptionTitle="Get detailed insights into your coding habits and patterns."
      description="Identify coding bottlenecks, improve your skills, track which languages and IDEs you use most, and discover when you&lsquo;re most likely to enter a flow state."
    >
      <AnalyticsCarousel />
    </FeatureBlock>
  );
}
