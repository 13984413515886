import TimeTrackingGraph from "../landing-interactables/time-tracking-graph";
import FeatureBlock from "./feature";

export default function TimeTracking() {
  return (
    <FeatureBlock
      title="Time Tracking"
      descriptionTitle="See exactly how long you spend on each coding session or task."
      description="Stay productive, focus on what matters most, and keep yourself informed with accurate time tracking."
    >
      <div className="container h-[450px] w-screen ">
        <TimeTrackingGraph />
      </div>
    </FeatureBlock>
  );
}
