import Link from "next/link";
import { twMerge } from "tailwind-merge";
import { Content } from "./content";
import { buttonVariants } from "./ui/button";

import HeroImage from "@/assets/images/dashboardOct24.png";
import { ZoomableImage } from "./enlarger";

const MiniBanner = () => (
  <a
    href={`${process.env.NEXT_PUBLIC_APP_URL}/login`}
    className="inline-flex items-center justify-center rounded border border-transparent bg-purple-200 py-1 pl-1 pr-4 text-sm font-medium leading-snug text-purple-500 shadow transition duration-150 ease-in-out"
  >
    <p className="mr-2 inline-flex items-center justify-center rounded border border-transparent bg-purple-500 px-4 py-1 font-medium leading-snug text-white shadow transition duration-150 ease-in-out">
      New
    </p>
    Create an Account for Early Access!
  </a>
);

const Title = () => (
  <>
    <div className="space-y-3">
      <h1 className="leading-tighter text-5xl font-extrabold tracking-tighter">
        You code. We count. <br />
        <span className="bg-gradient-to-r from-violet-500 to-purple-500 bg-clip-text text-transparent">
          Time Tracking & Analytics
        </span>
      </h1>
      <p className="text-text-primary text-2xl leading-normal">
        Download our Visual Studio Code extension to begin <br />
        tracking your time spent coding and get analytics on your coding habits.
      </p>
    </div>
    <div className="mx-auto space-x-3 space-y-2 sm:space-y-0">
      <a
        href={`${process.env.NEXT_PUBLIC_APP_URL}/login`}
        className={twMerge(buttonVariants({ variant: "purple", size: "lg" }))}
      >
        Create an account
        <i className="las la-plus-circle text-xl font-light"></i>
      </a>
      <Link
        href="https://github.com/chronoly/"
        target="_blank"
        rel="noopener noreferrer"
        className={twMerge(
          buttonVariants({ variant: "default", size: "lg" }),
          "shadow-lg"
        )}
      >
        View Extension
      </Link>
    </div>
  </>
);

export default function Hero() {
  return (
    <Content>
      <div className="space-y-8 text-center">
        <MiniBanner />
        <Title />

        <div className="flex items-center justify-center pt-6">
          <div className="relative">
            {" "}
            <div className="absolute -inset-2 rounded-lg bg-gradient-to-r from-[#7C7D7F] to-[#7C7D7F] opacity-25 blur"></div>
            <ZoomableImage src={HeroImage} />
          </div>
        </div>
      </div>
    </Content>
  );
}
