import HeroImage from "@/assets/images/dashboardOct24.png";
import HeroImage2 from "@/assets/images/dashboardRotatedOct24.png";
import { StaticImport } from "next/dist/shared/lib/get-img-props";
import Image from "next/image";
import { Dialog, DialogContent, DialogTrigger } from "./ui/dialogue";

type ZoomableImageProps = {
  src: StaticImport;
  alt?: string;
};

export const ZoomableImage = ({ src, alt }: ZoomableImageProps) => {
  if (!src) return null;
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Image
          src={HeroImage}
          alt="Hero Image"
          className="relative rounded-2xl border-2 border-gray-300"
        />
      </DialogTrigger>
      <DialogContent className="max-w-8xl border-0 bg-transparent p-0">
        <div className="relative h-[calc(100vh-50px)] w-full overflow-clip rounded-md bg-transparent">
          <div className="hidden md:block">
            <Image
              src={HeroImage}
              fill
              alt={alt || ""}
              className="h-full w-full object-contain"
            />
          </div>
          <div className="block md:hidden">
            <Image
              src={HeroImage2}
              fill
              alt={alt || ""}
              className="h-full w-full object-contain"
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
