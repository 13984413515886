import invoice from "@/assets/images/invoice-landing-short.svg";
import Image from "next/image";
import { Button } from "../ui/button";
import FeatureBlock from "./feature";

export default function Invoice() {
  return (
    <FeatureBlock
      title="Invoice Generation"
      descriptionTitle="Save time and eliminate manual entry."
      description="Create customizable invoices that automatically pull in your time-tracking data."
    >
      <Image src={invoice} width={800} height={400} alt={""} className="mt-8" />
      <a
        href="/chronoly-landing-invoice.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button variant={"purple"} className="my-8 flex mx-auto">
          Open PDF
        </Button>
      </a>
    </FeatureBlock>
  );
}
