export function formatDuration(milliseconds: number, trimSeconds: boolean = false): string {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const minutesLeft = minutes % 60;
  const hoursLeft = hours % 24;
  const secondsLeft = Math.floor(seconds % 60);

  if (trimSeconds) {
    if (days > 0) return `${days}d${hoursLeft > 0 ? ` ${hoursLeft}h` : ''}${minutesLeft > 0 ? ` ${minutesLeft}m` : ''}`;
    if (hours > 0) return `${hours}h${minutesLeft > 0 ? ` ${minutesLeft}m` : ''}`;
    if (minutes > 0) return `${minutes}m`;
    if (minutes < 0) return `${seconds}s`;
    return '0s';
  } else {
    if (days > 0) return `${days}d${hoursLeft > 0 ? ` ${hoursLeft}h` : ''}${minutesLeft > 0 ? ` ${minutesLeft}m` : ''}${secondsLeft > 0 ? ` ${secondsLeft}s` : ''}`;
    if (hours > 0) return `${hours}h${minutesLeft > 0 ? ` ${minutesLeft}m` : ''}${secondsLeft > 0 ? ` ${secondsLeft}s` : ''}`;
    if (minutes > 0) return `${minutes}m${secondsLeft > 0 ? ` ${secondsLeft}s` : ''}`;
    if (seconds > 0) return `${seconds}s`;
    return '0s';
  }
}

export function generateTicks(maxValue: number): number[] {
  // "Sufficient for any conceivable graphing needs unless you’re planning to account for the rise and fall of civilizations." - ChatGPT
  const roundedIntervals = [
    60, // 1 minute
    300, // 5 minutes
    600, // 10 minutes
    900, // 15 minutes
    1800, // 30 minutes
    3600, // 1 hour
    7200, // 2 hours
    10800, // 3 hours
    21600, // 6 hours
    43200, // 12 hours
    86400, // 24 hours (1 day)
    172800, // 2 days
    259200, // 3 days
    604800, // 1 week
    1209600, // 2 weeks
    1814400, // 3 weeks
    2419200, // 4 weeks
    4838400, // 2 months
    7257600, // 3 months
    14515200, // 6 months
    29030400, // 1 year
  ];

  const ticks: number[] = [];

  const interval = roundedIntervals.find((int) => int * 5 >= maxValue) || roundedIntervals[roundedIntervals.length - 1];

  for (let i = 0; i <= maxValue + interval!; i += interval!) {
    ticks.push(i * 1000);
  }

  return ticks;
}

function hashKeyToIndex(key: string, range: number): number {
  let hash = 0,
    hash2 = 1;
  for (let i = 0; i < key.length; i++) {
    hash = (hash * 31 + key.charCodeAt(i)) >>> 0; // Use a prime number as multiplier
    hash2 = (hash2 * 17 + key.charCodeAt(i)) >>> 0; // Another hash with a different prime
  }
  // Combine both hashes to get a more unique value
  hash = hash ^ (hash2 << 1);
  return Math.abs(hash) % range;
}

const GRAPH_COLORS = ['#208eb7', '#62d7e1', '#1c4c5e', '#a3c9fe', '#30408d', '#7771ff', '#edb1ff', '#621da6', '#eb67f9', '#ab6eaf'];

export function stringToColor(key: string): string {
  const index = hashKeyToIndex(key, GRAPH_COLORS.length);
  return GRAPH_COLORS[index] ?? '#000000';
}