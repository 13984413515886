import { twMerge } from "tailwind-merge";

export function Content({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <section className="space-y-6 pb-8 pt-6 md:pb-12 md:pt-10 lg:py-32">
      <div className={twMerge("mx-auto max-w-7xl px-4 sm:px-6", className)}>
        {children}
      </div>
    </section>
  );
}
