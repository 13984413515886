import Autoplay from "embla-carousel-autoplay";
import Image from "next/image";

// 120px height images
import averageSession from "@/assets/images/widgets/average-session.svg";
import codingTime from "@/assets/images/widgets/coding-time.svg";
import mostUtilizedHour from "@/assets/images/widgets/most-utilized-hour.svg";
import topProject from "@/assets/images/widgets/top-project.svg";

// 183px height images
import cumulativeAverage from "@/assets/images/widgets/cumulative-average.svg";
import deepCoding from "@/assets/images/widgets/deep-coding.svg";
import languages from "@/assets/images/widgets/languages.svg";
import projects from "@/assets/images/widgets/projects.svg";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../ui/carousel";

export default function AnalyticsCarousel() {
  return (
    <div className="space-y-8 mx-20">
      <Carousel
        opts={{
          align: "start",
          loop: true,
        }}
        plugins={[
          Autoplay({
            delay: 4000,
          }),
        ]}
      >
        <CarouselContent className="-ml-2 md:-ml-4">
          <CarouselItem className="pl-2 md:pl-4 md:basis-1/2 lg:basis-1/3 flex justify-center">
            <Image src={averageSession} alt="" />
          </CarouselItem>
          <CarouselItem className="pl-2 md:pl-4 md:basis-1/2 lg:basis-1/3 flex justify-center">
            <Image src={codingTime} alt="" />
          </CarouselItem>
          <CarouselItem className="pl-2 md:pl-4 md:basis-1/2 lg:basis-1/3 flex justify-center">
            <Image src={mostUtilizedHour} alt="" />
          </CarouselItem>
          <CarouselItem className="pl-2 md:pl-4 md:basis-1/2 lg:basis-1/3 flex justify-center">
            <Image src={topProject} alt="" />
          </CarouselItem>
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
      <Carousel
        opts={{
          align: "start",
          loop: true,
        }}
        plugins={[
          Autoplay({
            delay: 4000,
          }),
        ]}
      >
        <CarouselContent className="-ml-2 md:-ml-4">
          <CarouselItem className="pl-2 md:pl-4 md:basis-1/2 lg:basis-1/3 flex justify-center">
            <Image src={cumulativeAverage} alt="" />
          </CarouselItem>
          <CarouselItem className="pl-2 md:pl-4 md:basis-1/2 lg:basis-1/3 flex justify-center">
            <Image src={deepCoding} alt="" />
          </CarouselItem>
          <CarouselItem className="pl-2 md:pl-4 md:basis-1/2 lg:basis-1/3 flex justify-center">
            <Image src={languages} alt="" />
          </CarouselItem>
          <CarouselItem className="pl-2 md:pl-4 md:basis-1/2 lg:basis-1/3 flex justify-center">
            <Image src={projects} alt="" />
          </CarouselItem>
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </div>
  );
}
