import { twMerge } from "tailwind-merge";
import { buttonVariants } from "../ui/button";
import FeatureBlock from "./feature";

export default function CallToAction() {
  return (
    <FeatureBlock
      title="Sign up now"
      descriptionTitle="Get full access to all features."
      description="As a beta user, you&lsquo;ll be grandfathered into a premium account when we launch paid plans—completely free forever!"
    >
      <div className="space-x-3">
        <a
          href={`${process.env.NEXT_PUBLIC_APP_URL}/register`}
          className={twMerge(
            buttonVariants({ variant: "purple", size: "default" }),
            "mt-2"
          )}
        >
          Sign up
        </a>
        <a
          href={`/blog/getting-started`}
          className={twMerge(
            buttonVariants({ variant: "default", size: "default" }),
            "mt-2"
          )}
        >
          Learn more
        </a>
      </div>
    </FeatureBlock>
  );
}
